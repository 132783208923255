import React, { useEffect, useState, useContext, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
const Coin = "https://playerstorage.b-cdn.net/quiztwiz/assets/coin.svg";
import SidePoster from "../SidePoster/SidePoster";
import { toast, ToastContainer } from "react-toastify";
import { getQuestions } from "../../API/Question";
const afterReward =
  "https://playerstorage.b-cdn.net/quiztwiz/assets/rewarded.gif";
import { useDispatch, useSelector } from "react-redux";
import { addUser } from "../../GlobalStorage/actions/user";
import { getUser, updateUserCoins } from "../../API/Auth";
import { IoIosNotifications } from "react-icons/io";
import GoogleAds from "../../GoogleAds";
import showAds from "../../showAds";
import pushNotification from "./pushnotification.js";
// import { ClientID } from "../../GlobalStorage/actions/client";
import { CheckComponent } from "../../LoadContext";
// import { sendData } from "../../API/Question";
// import Login from "../Register/Login";
import useAnalyticsEventTracker from "../../useAnalyticsEventTracker";
import RewardModal from "./RewardModal";
import { useCookies } from "react-cookie";

import { updateUserProfile } from "../../GlobalStorage/actions/userActions";
import PopUp from "../../PopUp.jsx";
import AnchorAd from "../../AnchorAd.jsx";
import { Button } from "./button";
import { GameCard } from "./game-card";
import { CategoryButton } from "./category-button";
import { formControlClasses } from "@mui/material";

const Starter = (props) => {
  localStorage.removeItem("localCoins");
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [qData, setQData] = useState(null);
  const { startLoad, checkPage } = useContext(CheckComponent);
  const [data, setData] = useState(null);
  const [count, setCount] = useState(0);
  const [correctCount, setCorrectCount] = useState(0);
  const [ans, setAns] = useState(null);
  const [correct, setCorrect] = useState(null);
  const [wrong, setWrong] = useState(null);
  const trackEvent = useAnalyticsEventTracker();
  let userData = useSelector((state) => state.userReducer);
  const firstGridRef = useRef(null);
  // console.log("userData-->", userData);
  let refreshUser = useSelector((state) => state.refreshUser);
  let { cat } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [adViewedComplete, setAdViewedComplete] = useState(false);
  const isMobile = () => {
    let check = false;
    ((a) => {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };
  console.log("started page rendred");
  useEffect(() => {
    const token = cookies.token;
    if (token) {
      // Check if token exists
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [cookies.token]);

  let onMobile = isMobile();
  let clientid = useSelector((state) => state.clientId);
  // console.log(cat);
  const { search } = useLocation();
  // console.log("search", search);
  const searchParams = new URLSearchParams(search);

  // console.log("search params", searchParams);
  let teams = [];
  for (const p of searchParams) {
    teams.push(p[1]);
  }
  let subdomain = window.location.href.split("//")[1].split(".")[0];
  if (subdomain === "soapopera") {
    subdomain = "soaps";
  }

  let url = window.location.href;

  const urlObject = new URL(url);

  // Extract the link parameter value
  const linkParameter = urlObject.searchParams.get("link");
  let redirectLink = linkParameter;

  const imageParameter = urlObject.searchParams.get("image");
  let imageUrl = imageParameter;
  // console.log("link param", linkParameter);

  let domainList = [
    "monetix-nksolution2",
    "yapps",
    "dailyjanakantha",
    "monetix-logicgo1",
    "ittefaq",
    "monetix-mbinfotech-1",
    "monetix-mbinfotech",
    "monetix-logicgo2",
    "monetix-bhaveshbhai",
    "shreenathji1",
    "mathrubhumi",
    "hotaykiviral",
    "dainikamadershomoy",
    "monetix-vishvambhari1",
    "monetix-vishvambhari2",
    "monetix-vishvambhari3",
    "monetix-nksolution1",
    "monetix-dreaminfotech",
    "banglaxp",
    "cricket",
    "ragalahari",
    "biggbossteluguvoteonline",
    "biggboss7teluguvote",
    "biggbosstamilvote",
    "biggbossteluguvote-quiz",
    "saqlen",
    "bgmgfx",
    "ncis",
    "soaps",
    "sudhir",
    "kamal",
    "mtnews24",
    "novaneural",
    "rinzler",
    "lembark",
    "lathiyainfotech",
    "novaneural2",
    "androtech",
    "samsul",
    "openweb",
    "winacle",
    "lewishamilton",
    "lpbw1",
    "sisterwives1",
    "novaneural3",
    "lolo",
    "coreaspire",
    "lembark2",
    "hws",
    "wooper1",
    "wooper2",
    "wooper3",
    "wooper4",
    "wooper5",
    "wooper6",
    "wooper7",
    "novaneural4",
    "newspaper24hr",
    "mahadev",
    "radheytechnology",
    "bengalyojana",
    "woxxin2",
    "vuvu",
    "radheytechnology2",
    "defaultnew",
    "capity",
    "alldaydigital1",
    "radheytechnology3",
    "radheytechnology4",
    "dino",
    "lathiyainfotech2",
    "shreenathinfotech",
    "defaultnew2",
    "defaultnew3",
    "default5",
    "default6",
    "default7",
    "native",
    "abp",
    "moneycontrol",
    "mscfootball",
    "newswakerquiz",
    "harsh",
    "monetix1",
    "monetix2",
    "monetix3",
    "monetix4",

    "monetix6",
    "monetix7",
    "monetix8",
    "monetix9",
    "monetix10",
    "monetix11",
    "monetix12",
    "monetix13",
    "monetix14",
    "monetix15",
    "monetix16",
    "monetix17",
    "monetix18",
    "monetix19",
    "monetix20",
    "monetix21",
    "monetix22",
    "monetix23",

    "monetix25",
    "monetix26",
    "monetix27",
    "monetix28",
    "monetix29",

    "monetix31",
    "monetix32",
    "monetix33",
    "novaneural1",
    "striking",
    "fskintools",
    "abhishek",
    "default3",
    "default4",
    "sangbadpratidin",
    "viraldailykhabar",
    "igamez",
    "madhyamam",
    "ecosoft1",
  ];
  let defaultNewDomains = [
    "monetix-nksolution2",
    "yapps",
    "dailyjanakantha",
    "monetix-mbinfotech-1",
    "ittefaq",
    "monetix-bhaveshbhai",
    "monetix-logicgo1",
    "monetix-logicgo2",
    "monetix-mbinfotech",
    "monetix-vishvambhari1",
    "monetix-vishvambhari2",
    "monetix-vishvambhari3",
    "monetix-nksolution1",
    "monetix-dreaminfotech",
    "banglaxp",
    "monetix29",

    "monetix31",
    "monetix32",
    "monetix33",
    "alldaydigital1",
    "shreenathji1",
    "hotaykiviral",
    "biggbossteluguvoteonline",
    "biggboss7teluguvote",
    "biggbosstamilvote",
    "biggbossteluguvote-quiz",
    "ragalahari",
    "saqlen",
    "sangbadpratidin",
    "bgmgfx",
    "viraldailykhabar",
    "igamez",
    "madhyamam",
    "abhishek",
    "default3",
    "default4",
    "sangbadpratidin",
    "viraldailykhabar",
    "igamez",
    "monetix1",
    "defaultnew2",
    "defaultnew3",
    "monetix19",
    "monetix20",
    "monetix21",
    "monetix22",
    "monetix23",
    "monetix23",

    "monetix25",
    "monetix26",
    "monetix27",
    "monetix28",
    "monetix2",
    "monetix3",
    "monetix4",

    "monetix6",
    "monetix7",
    "monetix8",
    "monetix9",
    "monetix10",
    "monetix11",
    "monetix12",
    "monetix13",
    "monetix14",
    "monetix15",
    "monetix16",
    "monetix17",
    "monetix18",
    "novaneural1",
    "striking",
    "fskintools",
  ];
  let rinzlerDomains = [
    "novaneural",
    "rinzler",
    "lathiyainfotech",
    "novaneural2",
    "androtech",
    "samsul",
    "openweb",
    "novaneural3",
    "lolo",
    "coreaspire",
    "hws",
    "wooper1",
    "wooper2",
    "wooper3",
    "wooper4",
    "wooper5",
    "wooper6",
    "wooper7",
    "novaneural4",
    "newspaper24hr",
    "mahadev",
    "radheytechnology",
    "bengalyojana",
    "woxxin2",
    "vuvu",
    "radheytechnology2",
    "capity",
    "radheytechnology3",
    "radheytechnology4",
    "dino",
    "lathiyainfotech2",
    "shreenathinfotech",
  ];
  let sampleQuestions = [
    {
      question: "Which country is Lionel Messi from?",
      answers: ["Brazil", "Argentina", "Spain", "Portugal"],
      correct: "Argentina",
      coins: "10",
      quizName: "dtt360",
      language: "english",
    },
    {
      question:
        "Which number is famously associated with Messi’s jersey at Barcelona?",
      answers: ["7", "9", "10", "11"],
      correct: "10",
      coins: "10",
      quizName: "dtt360",
      language: "english",
    },
    {
      question: "What is Messi’s full name?",
      answers: [
        "Lionel Andres Messi",
        "Lionel Antonio Messi",
        "Lionel Alberto Messi",
        "Lionel Alejandro Messi",
      ],
      correct: "Lionel Andres Messi",
      coins: "10",
      quizName: "dtt360",
      language: "english",
    },
    {
      question: "Which club did Messi join after leaving FC Barcelona?",
      answers: [
        "Manchester City",
        "Paris Saint-Germain",
        "Inter Miami",
        "Juventus",
      ],
      correct: "Paris Saint-Germain",
      coins: "10",
      quizName: "dtt360",
      language: "english",
    },
    {
      question: "In what year did Messi win his first FIFA World Cup?",
      answers: ["2010", "2014", "2022", "2018"],
      correct: "2022",
      coins: "10",
      quizName: "dtt360",
      language: "english",
    },
    {
      question: "How tall is Lionel Messi?",
      answers: ["5'6\"", "5'8\"", "5'10\"", "6'0\""],
      correct: "5'6\"",
      coins: "10",
      quizName: "dtt360",
      language: "english",
    },
    {
      question:
        "How old was Messi when he moved to Barcelona’s academy, La Masia?",
      answers: ["9", "11", "13", "15"],
      correct: "13",
      coins: "10",
      quizName: "dtt360",
      language: "english",
    },
    {
      question: "Which of these awards has Messi won the most times?",
      answers: [
        "Ballon d'Or",
        "Golden Boot",
        "UEFA Best Player",
        "FIFA Best Player",
      ],
      correct: "Ballon d'Or",
      coins: "10",
      quizName: "dtt360",
      language: "english",
    },
    {
      question: "What is the name of Messi’s wife?",
      answers: [
        "Sofia Balbi",
        "Antonela Roccuzzo",
        "Georgina Rodriguez",
        "Shakira",
      ],
      correct: "Antonela Roccuzzo",
      coins: "10",
      quizName: "dtt360",
      language: "english",
    },
    {
      question: "What is Lionel Messi’s nickname?",
      answers: ["La Pulga", "El Maestro", "El Toro", "El Matador"],
      correct: "La Pulga",
      coins: "10",
      quizName: "dtt360",
      language: "english",
    },
    {
      question: "Who is known as 'The Black Mamba' in basketball?",
      answers: [
        "Michael Jordan",
        "Kobe Bryant",
        "LeBron James",
        "Shaquille O'Neal",
      ],
      correct: "Kobe Bryant",
      coins: "10",
      quizName: "dtt360",
      language: "english",
    },
    {
      question: "Who is called ‘The King of Football’?",
      answers: ["Diego Maradona", "Lionel Messi", "Cristiano Ronaldo", "Pelé"],
      correct: "Pelé",
      coins: "10",
      quizName: "dtt360",
      language: "english",
    },
    {
      question: "Who is known as ‘The Flying Sikh’ in athletics?",
      answers: ["Usain Bolt", "Milkha Singh", "Carl Lewis", "Mo Farah"],
      correct: "Milkha Singh",
      coins: "10",
      quizName: "dtt360",
      language: "english",
    },
    {
      question: "Which tennis player is nicknamed ‘The FedExpress’?",
      answers: [
        "Novak Djokovic",
        "Rafael Nadal",
        "Roger Federer",
        "Andy Murray",
      ],
      correct: "Roger Federer",
      coins: "10",
      quizName: "dtt360",
      language: "english",
    },
    {
      question: "Who is famously known as 'The Greatest' in boxing?",
      answers: [
        "Mike Tyson",
        "Muhammad Ali",
        "Floyd Mayweather",
        "Joe Frazier",
      ],
      correct: "Muhammad Ali",
      coins: "10",
      quizName: "dtt360",
      language: "english",
    },
  ];

  // console.log("subdomain", subdomain);
  const games = [
    {
      title: "Bollywood",
      image:
        "https://playerstorage.b-cdn.net/quiztwiz/categoryimages/bollywood.jpg",
      questions: "62bad4d6ecd55188b51a27ae",
    },
    {
      title: "Hollywood",
      image:
        "https://playerstorage.b-cdn.net/quiztwiz/categoryimages/hollywood.jpg",
      questions: "62d10f2bb1a7fc8769355c3d",
    },
    {
      title: "KBC",
      image: "https://playerstorage.b-cdn.net/quiztwiz/categoryimages/kbc.jpg",
      questions: "62e8f730cefb4253115a7156",
    },
    {
      title: "Cricket",
      image:
        "https://playerstorage.b-cdn.net/quiztwiz/categoryimages/cricket.jpg",
      questions: "62d11072b1a7fc8769355c5b",
    },
    {
      title: "Football",
      image:
        "https://playerstorage.b-cdn.net/quiztwiz/categoryimages/football.jpg",
      questions: "62d110d3b1a7fc8769355c66",
    },
    {
      title: "GK",
      image: "https://playerstorage.b-cdn.net/quiztwiz/categoryimages/gk.jpg",
      questions: "62d11238b1a7fc8769355c8e",
    },
    {
      title: "English to हिन्दी",
      image:
        "https://playerstorage.b-cdn.net/quiztwiz/categoryimages/translate.jpg",
      questions: "62b98206cb0e065e440cdaff",
    },
    {
      title: "Science",
      image:
        "https://playerstorage.b-cdn.net/quiztwiz/categoryimages/science.jpg",
      questions: "62ba82aaecd55188b51a2772",
    },
  ];

  function startGame(id) {
    let object = { from: "playgame" };
    handleClaim(object, id);
  }
  // useEffect(() => {
  //   function getMultipleRandom(arr, num, isFiltered) {
  //     // console.log("array data", arr);
  //     if (!isFiltered) {
  //       arr = arr.data.data;
  //     }
  //     const shuffled = [...arr].sort(() => 0.5 - Math.random());
  //     return shuffled.slice(0, num);
  //   }
  //   const pullQuestions = async () => {
  //     let filtered;
  //     if (
  //       (sessionStorage.getItem("localCoins") != null ||
  //         userData.user !== null) &&
  //       cat !== "t20" &&
  //       cat !== "football"
  //     ) {
  //       history.push("/home");
  //     } else if (cat) {
  //       // let startQs = await getQuestions(null, null, null, true);

  //       let startQs;
  //       if (cat === "t20" || cat === "football") {
  //         // console.log("questions of t20 world cup");
  //         console.log("teams", teams);
  //         if (
  //           teams[0] === "" ||
  //           teams[0] === "undefined" ||
  //           teams[1] === "" ||
  //           teams[1] === "undefined"
  //         ) {
  //         } else {
  //           startQs = await getQuestions(null, null, null, true, "iplNewQuiz");
  //         }
  //         // console.log(startQs);
  //         // console.log(startQs ,"line 74");
  //         filtered = startQs?.data?.data?.filter(
  //           (item) =>
  //             item.quizName ===
  //               (teams[0] === "" || teams[0] === "undefined"
  //                 ? "iplNewQuiz"
  //                 : teams[0]) ||
  //             item.quizName ===
  //               (teams[1] === "" || teams[1] === "undefined"
  //                 ? "iplNewQuiz"
  //                 : teams[1])
  //         );
  //         // console.log(filtered)
  //         let random = getMultipleRandom(startQs, 10);
  //         // console.log(random);
  //         setQData(random);
  //       } else {
  //         filtered = startQs.data.data.filter((item) => item.quizName === cat);
  //         let random = getMultipleRandom(filtered, 3);
  //         setQData(random);
  //       }
  //     } else if (subdomain === "cricketz") {
  //       // console.log("inside cric")
  //       let startQs = await getQuestions(null, null, null, true, "india");
  //       // console.log("startQs", startQs)
  //       let random = getMultipleRandom(startQs, 10);
  //       setQData(random);
  //     } else if (subdomain === "opportunitiesbrazil") {
  //       let startQs = await getQuestions(null, null, null, true, "bra");
  //       // console.log("startQs", startQs)
  //       let random = getMultipleRandom(startQs, 5);
  //       setQData(random);
  //     } else if (subdomain === "oportunidadesbrasil1") {
  //       let startQs = await getQuestions(null, null, null, true, "braPort");
  //       // console.log("startQs", startQs)
  //       let random = getMultipleRandom(startQs, 10);
  //       setQData(random);
  //     } else if (subdomain === "oportunidadesbrasil2") {
  //       let startQs = await getQuestions(null, null, null, true, "braSpanish");
  //       // console.log("startQs", startQs)
  //       let random = getMultipleRandom(startQs, 10);
  //       setQData(random);
  //     } else if (domainList.includes(subdomain)) {
  //       console.log("inside domain list");
  //       if (rinzlerDomains.includes(subdomain)) {
  //         let startQ = await getQuestions(null, null, null, true, "rinzler");
  //         filtered = startQ;
  //         // return item.quizName === "rinzler";
  //       } else if (subdomain === "lewishamilton" || subdomain === "winacle") {
  //         let startQ = await getQuestions(null, null, null, true, "winacle");
  //         filtered = startQ;
  //         // return item.quizName === "winacle";
  //       } else if (subdomain === "lembark2") {
  //         let startQ = await getQuestions(null, null, null, true, "lembark");
  //         filtered = startQ;
  //         // return item.quizName === "lembark";
  //       } else if (
  //         subdomain === "default5" ||
  //         subdomain === "default6" ||
  //         subdomain === "default7"
  //       ) {
  //         let startQ = await getQuestions(null, null, null, true, "ipl2023");
  //         filtered = startQ;
  //         // return item.quizName === "ipl2023";
  //       } else if (subdomain === "abp") {
  //         let startQ = await getQuestions(null, null, null, true, "native");
  //         filtered = startQ;
  //         // return item.quizName === "native";
  //       } else if (defaultNewDomains.includes(subdomain)) {
  //         console.log("defaultnew domains fetch");
  //         let startQ = await getQuestions(null, null, null, true, "defaultnew");
  //         filtered = startQ;
  //         // return item.quizName === "defaultnew";
  //       } else if (subdomain === "harsh") {
  //         let startQ = await getQuestions(
  //           null,
  //           null,
  //           null,
  //           true,
  //           "HinduReligion"
  //         );
  //         filtered = startQ;
  //         // return item.quizName === "HinduReligion";
  //       } else if (subdomain === "mtnews24") {
  //         let startQ = await getQuestions(null, null, null, true, "infobidz");
  //         filtered = startQ;
  //       } else if (
  //         subdomain === "mathrubhumi" ||
  //         subdomain === "dainikamadershomoy"
  //       ) {
  //         console.log("cricket questions");
  //         let startQ = await getQuestions(null, null, null, true, "cricket");
  //         filtered = startQ;
  //       } else {
  //         console.log("inside subdomains condition");
  //         let startQ = await getQuestions(null, null, null, true, subdomain);
  //         filtered = startQ;
  //         // return item.quizName === subdomain;
  //       }

  //       if (subdomain === "kamal") {
  //         let random = getMultipleRandom(filtered, 2);
  //         setQData(random);
  //       } else if (subdomain === "winacle" || subdomain === "lewishamilton") {
  //         let random = getMultipleRandom(filtered, 2);
  //         setQData(random);
  //       } else if (
  //         subdomain === "lpbw1" ||
  //         subdomain === "sisterwives1" ||
  //         subdomain === "mtnews24"
  //       ) {
  //         let random = getMultipleRandom(filtered, 2);
  //         setQData(random);
  //       } else if (
  //         defaultNewDomains.includes(subdomain) ||
  //         subdomain === "cricket" ||
  //         subdomain === "ecosoft1" ||
  //         subdomain === "dainikamadershomoy" ||
  //         subdomain === "mathrubhumi"
  //       ) {
  //         let random = getMultipleRandom(filtered, 2);
  //         setQData(random);
  //       } else if (subdomain === "native" || subdomain === "abp") {
  //         let random = getMultipleRandom(filtered, 2);
  //         setQData(random);
  //       } else {
  //         let random = getMultipleRandom(filtered, 2);
  //         setQData(random);
  //       }
  //     } else {
  //       let startQs = await getQuestions(null, null, null, true, subdomain);

  //       if (startQs.error) {
  //         toast.error(startQs.error.message, {
  //           position: "top-right",
  //           autoClose: 3000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "dark",
  //         });
  //       } else {
  //         let filtered = startQs.data.data.filter(
  //           (item) =>
  //             item.language !== "hindi" &&
  //             item.language !== "bengali" &&
  //             item.language !== "gujarati" &&
  //             item.language !== "image" &&
  //             item.language !== "tamil" &&
  //             item.language !== "marathi" &&
  //             item.language !== "chinese" &&
  //             item.language !== "spanish" &&
  //             item.language !== "Portuguese"
  //         );

  //         let random = getMultipleRandom(filtered, 2, true);

  //         setQData(random);
  //       }
  //     }
  //   };
  //   pullQuestions();
  // }, []);
  // let subdomain = window.location.href.split("//")[1].split(".")[0];
  // if (subdomain === "soapopera") {
  //   subdomain = "soaps";
  // }
  // subdomain = "defaultnew";

  // let subdomain = window.location.href.split("//")[1].split(".")[0];
  // if (subdomain === "soapopera") {
  //   subdomain = "soaps";
  // }
  // subdomain = "defaultnew";
  useEffect(() => {
    if (
      sessionStorage.getItem("localCoins") == null &&
      sessionStorage.getItem("quizData") == null &&
      sessionStorage.getItem("userData") == null
    ) {
      checkPage();
      // console.log("checkpage function ran");
    }
  }, []);
  // useEffect(() => {
  //   const getdata = async () => {
  //     let res = await getUser();
  //     if (res.error == null) {
  //       dispatch(addUser(res.data.data));
  //       history.push("/home");
  //     }
  //   };
  //   getdata();
  // }, []);
  let fromLogin;
  if (props.location.state) {
    fromLogin = props.location.state;
    // console.log(fromResult.fromResult);
    // console.log("fromLogin", fromLogin)
  }

  const next = (key) => {
    let result = null;
    if (key == data.correct) {
      setCorrect(data.correct);
      setCorrectCount((prev) => prev + 1);
      result = true;
    } else {
      setCorrect(data.correct);
      setWrong(key);
      result = false;
    }
    setTimeout(() => {
      setAns(result);
    }, 1000);
  };
  let preload = true;
  // useEffect(() => {
  //   function getMultipleRandom(arr, num) {
  //     const shuffled = [...arr].sort(() => 0.5 - Math.random());
  //     return shuffled.slice(0, num);
  //   }
  //   let random = getMultipleRandom(defaultNewQuestions, 10)
  //   setQData(random);
  // }, []);

  useEffect(() => {
    // console.log("count--", count)
    if (
      (count >= qData?.lenght - 1 && subdomain === "cricketz") ||
      (count >= qData?.lenght - 1 && subdomain === "oportunidadesbrazil2") ||
      (count >= qData?.lenght - 1 && subdomain === "oportunidadesbrazil") ||
      (count >= qData?.lenght - 1 && subdomain === "oportunidadesbrazil1")
    ) {
      console.log("set modal open");
      setIsModalOpen(true);
      history.push("/home");
    } else {
      if (qData) setData(qData[count]);
    }
  }, [qData, count]);

  useEffect(() => {
    if (!window.adsbygoogle) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
    window.adConfig = function (o) {
      // console.log("inside ad config fn");

      window.adsbygoogle.push(o);
    };
    window.adBreak = function (o) {
      // console.log("inside adBreak fn");
      window.adsbygoogle.push(o);
    };
    window.adConfig({
      preloadAdBreaks: "on",
    });
  }, []);

  useEffect(() => {
    // if (count === 1) {
    //   const script2 = document.createElement("script");
    //   script2.async = true;
    //   script2.src =
    //     "https://www.googletagmanager.com/gtag/js?id=AW-10821409998";

    //   // Create a function to initialize gtag
    //   window.dataLayer = window.dataLayer || [];
    //   function gtag() {
    //     window.dataLayer.push(arguments);
    //   }

    //   // Initialize gtag.js
    //   gtag("config", "AW-10821409998");

    //   // Append the script element to the document
    //   document.head.appendChild(script2);
    //   console.log("googletag2 code init");

    //   // Function to report conversion
    //   function googletag2_report_conversion() {
    //     console.log("googletag2 code inside");
    //     let callback = function () {
    //       // If needed, you can add code to handle the callback here
    //     };
    //     gtag("event", "conversion", {
    //       send_to: "AW-10821409998/JiptCLapzdgYEM65hqgo",
    //       event_callback: callback,
    //     });
    //     return false;
    //   }

    //   // Call the conversion function
    //   googletag2_report_conversion();
    // }
    if (count > 1) {
      // console.log("show ads called");
      // showAds();
      const script = document.createElement("script");
      script.text = `
          window.googletag = window.googletag || { cmd: [] };
      
          googletag.cmd.push(function () {
           
            // Define a web interstitial ad slot.
            let interstitialSlot = googletag.defineOutOfPageSlot(
              "/22082859479/quiztwiz_vignette",
              googletag.enums.OutOfPageFormat.INTERSTITIAL
            );
            interstitialSlot
                  .addService(googletag.pubads());
           
      
            // Slot returns null if the page or device does not support interstitials.
            if (interstitialSlot) {
              interstitialSlot
                .addService(window.googletag.pubads())
                .setConfig({
                  interstitial: {
                    triggers: {
                      unhideWindow: true,
                    },
                  },
                });
      
              // Add an event listener to handle when the slot loads
              window.googletag.pubads().addEventListener("slotOnload", function (event) {
                if (interstitialSlot === event.slot) {
                  console.log("Interstitial vignette is loaded.");
                }
              });
            }
            googletag.pubads().enableSingleRequest();
          googletag.enableServices();
          googletag.pubads().refresh([interstitialSlot]);
          });
        `;

      // Append the script to the head of the document
      document.head.appendChild(script);
      if (
        subdomain !== "cricketz" &&
        subdomain !== "opportunitiesbrazil" &&
        subdomain !== "oportunidadesbrasil1" &&
        subdomain !== "oportunidadesbrasil2"
      ) {
        setIsModalOpen(true);
      }
    }

    if (qData) setData(qData[count]);
  }, [count]);

  useEffect(() => {
    if (ans != null) {
      setCount((prev) => prev + 1);
      if (count === 0) {
        showAds();
      }
      if (count === 2) {
        trackEvent(
          "Question Played",
          "User Played 3rd question",
          "Played 3rd question"
        );
      }
      // console.log("question length", qData.length);?link=https://kloud.watch/76n47t6idnu4
      if (count >= qData.length - 1) {
        let guestCoins = { coins: correctCount * 50 };

        sessionStorage.setItem("localCoins", JSON.stringify(guestCoins));
        if (
          subdomain === "oportunidadesbrasil2" ||
          subdomain === "opportunitiesbrazil" ||
          subdomain === "oportunidadesbrasil1"
        ) {
          console.log("to home");
          history.push("/home");
        }
      }
      // if (count === 1 && window.location.hostname !== "localhost") {
      //   const dataLog = async () => {
      //     const res = await sendData();
      //     console.log("res", res);
      //   };
      //   dataLog();
      // }

      //   sessionStorage.clear();
      //   let guestCoins = { coins: correctCount * 50 };
      //   // console.log(guestCoins);
      //   sessionStorage.setItem("localCoins", JSON.stringify(guestCoins));
      // } else {
      if (count >= 9) {
        // console.log("count greater than 8")
        history.push("/home");
      }
      setCount(count + 1);
    }
    setCorrect(null);
    setWrong(null);
    setAns(null);
  }, [ans]);

  const LoginEvent = () => {
    trackEvent("button", "Sign up from Starter", "Sign Up");
  };

  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.innerText = `window.unibots = window.unibots || { cmd: [] };
  //   unibots.cmd.push(()=>{ unibotsPlayer("soaps"); });`;

  //   document.getElementById("div-ub-soaps").append(script);
  //   console.log(document.getElementById("div-ub-soaps"));
  // }, []);

  // useEffect(() => {
  //   window.unibots = window.unibots || { cmd: [] };
  //   window.unibots.cmd.push(() => {
  //     window.unibotsPlayer("soaps");
  //   });
  // }, []);
  const closeModal = () => {
    setIsModalOpen(false);
    window.interstatialFired = true;
    if (cat !== "t20" && cat !== "football") {
      // let routeData = { data: qData, count: count, correct: correctCount };
      // history.push(`/question/1`, routeData);
      history.push("/playquiz");
    }
    // console.log("routeData--", routeData);
  };
  const updateUserRewardCoins = async () => {
    let newCoins = userData.user.coins + 100;
    const res = await updateUserCoins(userData.user.email, newCoins);
    console.log("response after watching add", res);
    dispatch(updateUserProfile(res.data.data));
  };
  const handleClaim = (object, id) => {
    console.log("inside handle claim");
    let adRewardFlag = true;
    let adsActive = true;
    let adWatch = false;
    if (adsActive) {
      // window.adConfig = function (o) {
      //   window.adsbygoogle.push(o);
      // };
      // window.adBreak = function (o) {
      //   window.adsbygoogle.push(o);
      // };

      window.adBreak({
        type: "reward",
        name: "coins",
        beforeReward: (showAdFn) => {
          adRewardFlag = false;
          // console.log(adRewardFlag);
          showAdFn();
        },
        beforeReward: (showAdFn) => {
          try {
            adRewardFlag = false;
            showAdFn();
          } catch (error) {
            console.error("Error showing the ad:", error);
          }
        },
        adDismissed: () => {
          console.log("Ad Dismissed before time");
          window.interstatialFired = true;
          // let routeData = {
          //   data: qData,
          //   count: count,
          //   correct: correctCount,
          //   claimedCoins: 0,
          // };
          // history.push({
          //   pathname: "/playquiz",
          //   state: { routeData },
          // });
          if (object?.from === "first") {
            if (firstGridRef.current) {
              firstGridRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }
          } else {
            history.push({
              pathname: `/home/quiz/${id}`,
              state: { quizID: id },
            });
          }
        },

        adViewed: () => {
          console.log("Ad Dismissed after time");
          adRewardFlag = true;
          adWatch = true;
          showModal();
        },

        adBreakDone: () => {
          if (adRewardFlag && adWatch) {
            console.log("inside rewatd toast");
            // toast.success("500 Coins Rewarded", {
            //   position: "top-right",
            //   autoClose: 3000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            //   theme: "dark",
            // });
            // updateUserRewardCoins();
            window.interstatialFired = true;
            handleShowToast();
            let localCoins = sessionStorage.getItem("localCoins");

            // Parse it into a JavaScript object
            let coinsObject = localCoins
              ? JSON.parse(localCoins)
              : { coins: 0 }; // Default to 0 if not set

            // Add 100 coins
            coinsObject.coins += 100;

            // Save the updated object back to sessionStorage
            sessionStorage.setItem("localCoins", JSON.stringify(coinsObject));
            if (object?.from === "first") {
              if (firstGridRef.current) {
                firstGridRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }
            } else {
              history.push({
                pathname: `/home/quiz/${id}`,
                state: { quizID: id },
              });
            }

            // dispatch(refreshUser());
          } else if (adRewardFlag) {
            toast.error("RewardAds not available, Try Again", {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });

            window.interstatialFired = true;
          }
          if (object?.from === "first") {
            if (firstGridRef.current) {
              firstGridRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }
          } else {
            history.push({
              pathname: `/home/quiz/${id}`,
              state: { quizID: id },
            });
          }
          setIsModalOpen(false);
        },
      });

      // window.adConfig({
      //   preloadAdBreaks: "on",
      //   onReady: showAd,
      // });
    }
  };
  const showModal = () => {
    setIsModalOpen(true);
    setAdViewedComplete(true);
  };
  const CustomToast = ({ message, image }) => (
    <div className="flex justify-center items-center">
      <img src={image} alt="Notification" style={{ width: "100px" }} />
      <p>{message}</p>
    </div>
  );

  const handleShowToast = () => {
    const image = afterReward;
    const message = "100 coins Rewarded!!";

    toast(<CustomToast message={message} image={image} />, {
      autoClose: 1000, // Set the closing time to 5000 milliseconds (5 seconds)
    });
  };

  function redirectCloud() {
    const link = `intent://${redirectLink}#Intent;scheme=kloudwatch;package=com.apparent.playwell;end`;

    // Redirect to the link
    window.location.href = link;
  }
  function handleRewardClick(object) {
    handleClaim(object);
  }

  return (
    <>
      <ToastContainer
        toastClassName="my-toast-container-colored" // Use "my-toast-container-colored" for colored theme
        bodyClassName="my-toast-body-colored"
        theme="dark"
        // position="top-right"
        // autoClose={5000}
        // hideProgressBar={false}
        // newestOnTop={false}
        // closeOnClick
        // rtl={false}
        // pauseOnFocusLoss
        // draggable
        // pauseOnHover
      />
      {/* {cat !== "t20" && (
        <RewardModal
          isOpen={isModalOpen}
          onClose={closeModal}
          onClaim={handleClaim}
          adViewedComplete={adViewedComplete}
        />
      )} */}
      {
        <>
          {/* <AdContainerComponent /> */}

          <div className="text-white h-screen flex">
            <PopUp />

            <div className="max-w-[520px] scrollhide  lgm:max-w-[360px] md:w-full md:min-w-full max-h-screen flex flex-col gap-3 py-3 px-2 items-center  box-border">
              <div className="max-w-[480px] max-h-[320px] mobile-width">
                <GoogleAds
                  clientId={clientid}
                  startLoad={startLoad}
                  preload={preload}
                  fromLogin={fromLogin}
                  adSlot="start_inpage"
                />
              </div>
              {subdomain !== "monetix148" && <AnchorAd />}
              <main className="min-h-screen bg-[#0F172A] px-4 py-8">
                <div className="max-w-6xl mx-auto space-y-8">
                  {/* Header Section */}
                  <div className="bg-slate-800/50 backdrop-blur-sm border border-slate-700/50 rounded-3xl p-6 text-center shadow-lg">
                    <h1 className="text-slate-200 text-xl mb-4">
                      Redeem rewards to play free Quiz
                    </h1>
                    <Button
                      variant="primary"
                      className="text-lg"
                      onClick={() => handleRewardClick({ from: "first" })}
                    >
                      Lets Play <span className="ml-2">🔥</span>
                    </Button>
                  </div>

                  {/* Categories Section */}
                  <div className="bg-slate-800/50 backdrop-blur-sm border border-slate-700/50 rounded-3xl p-6 text-center shadow-lg">
                    <h2 className="text-slate-200 text-lg mb-4">
                      Choose which of these types of Quizes you prefer!
                    </h2>
                    <div className="flex flex-wrap justify-center gap-4">
                      <CategoryButton
                        icon="⚔️"
                        onClick={() => handleRewardClick({ from: "first" })}
                      >
                        Entertainment
                      </CategoryButton>
                      <CategoryButton
                        icon="👑"
                        onClick={() => handleRewardClick({ from: "first" })}
                      >
                        Knowledge
                      </CategoryButton>
                    </div>
                  </div>

                  {/* Games Grid */}
                  <div ref={firstGridRef} className="grid grid-cols-2 gap-6">
                    {games.map((game, index) => (
                      <GameCard
                        key={index}
                        title={game.title}
                        image={game.image}
                        gameId={game.questions}
                        playGame={() => startGame(game.questions)}
                      />
                    ))}
                  </div>
                </div>
                <div class="bg-[#0F172A] text-white px-2">
                  <div class="max-w-3xl mx-auto text-center">
                    <h2 class="text-3xl font-bold mb-6">About QuizTwiz</h2>
                    <p class="text-lg leading-relaxed mb-4">
                      Welcome to <span class="font-semibold">QuizTwiz</span>,
                      your ultimate destination for fun, learning, and
                      challenge! At QuizTwiz, we believe that learning can be
                      both entertaining and engaging. Our platform offers a wide
                      range of quizzes across various categories, catering to
                      trivia enthusiasts, knowledge seekers, and anyone who
                      loves a good challenge.
                    </p>
                    <p class="text-lg leading-relaxed mb-4">
                      Whether you're into history, sports, science,
                      entertainment, or pop culture, QuizTwiz has something for
                      everyone. Compete with friends, test your skills, and
                      discover new facts as you explore diverse topics.
                    </p>

                    <p class="text-lg leading-relaxed mt-6">
                      Get ready to play, learn, and challenge yourself with{" "}
                      <span class="font-semibold">QuizTwiz</span>!
                    </p>
                  </div>
                </div>
              </main>
            </div>
            <SidePoster />
          </div>
        </>
      }
    </>
  );
};

export default Starter;
