let isAdInitialized = false; // Track initialization state

export default function showAds() {
  // Only initialize once
  if (!isAdInitialized) {
    // Define ad functions once
    window.adConfig = function (o) {
      window.adsbygoogle.push(o);
    };

    window.adBreak = function (o) {
      window.adsbygoogle.push(o);
    };

    // Initial configuration
    window.adConfig({
      preloadAdBreaks: "on",
      onReady: () => {
        // Trigger first ad break automatically
        window.adBreak({
          type: "browse",
          name: "browse",
        });
      },
    });

    isAdInitialized = true;
  } else {
    // Subsequent calls trigger ad break directly
    window.adBreak({
      type: "browse",
      name: "browse",
    });
  }
}
