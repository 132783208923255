export function CategoryButton({ icon, children, ...props }) {
  return (
    <button
      className="flex items-center gap-2 bg-slate-700 hover:bg-slate-600 text-slate-200 py-3 px-6 rounded-full font-medium transition-all duration-200 hover:scale-105 border border-slate-600"
      {...props}
    >
      <span className="text-xl">{icon}</span>
      <span>{children}</span>
    </button>
  );
}
