export function Button({
  children,
  variant = "primary",
  className = "",
  ...props
}) {
  const baseStyles = "rounded-full font-medium transition-colors duration-200";
  const variants = {
    primary:
      "bg-indigo-500 hover:bg-indigo-600 text-white py-3 sm:px-6 px-4 text-sm sm:text-base",
    secondary: "bg-slate-700 hover:bg-slate-600 text-slate-200 py-2 px-4",
  };

  return (
    <button
      className={`${baseStyles} ${variants[variant]} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
}
