import { Button } from "./button";

export function GameCard({ title, image, gameId, playGame }) {
  return (
    <div className="bg-slate-800/50 backdrop-blur-sm rounded-3xl border border-slate-700/50 shadow-xl overflow-hidden flex flex-col hover:scale-105 transition-transform duration-300">
      <div className="aspect-square relative overflow-hidden">
        <img
          src={image || "/placeholder.svg"}
          alt={title}
          className="w-full h-full object-cover"
        />
      </div>
      <div className="p-4 flex flex-col items-center gap-2">
        <h3 className="font-bold text-slate-200 text-center">{title}</h3>
        <Button
          variant="primary"
          className="w-full"
          onClick={() => playGame(gameId)}
        >
          Play Game
        </Button>
      </div>
    </div>
  );
}
